import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { parse as parseQueryString } from 'query-string';
import { DebugProvider } from './common/Debug';
import { getClientParams } from './helpers';

const queryObject = parseQueryString(window.location.search);
const debug = queryObject.debug === '1';
const enableError = queryObject.enableError === '1';

if (process.env.NODE_ENV === 'development') {
    function deepCloneWithoutPrototype(obj) {
        if (typeof obj !== 'object' || obj === null) {
            return obj; // Return the value if obj is not an object
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => deepCloneWithoutPrototype(item)); // Handle arrays
        }

        const clone = Object.create(null); // Create a new object with no prototype

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                clone[key] = deepCloneWithoutPrototype(obj[key]); // Recursively clone nested objects
            }
        }

        return clone;
    }
    (async () => {
        try {
            const queryObject = parseQueryString(window.location.search);
            const isLoggedIn = localStorage.getItem('isLoggedIn');

            let linkToIndex = '';
            if (isLoggedIn !== 'true') {
                // request for obtaining the initial otp and transaction id link
                const response = await fetch(
                    `${process.env.REACT_APP_SMARTFACE_BASE_SERVER}/api/access?output=relativeLink${queryObject.channelId ? `&channelId=${queryObject.channelId}` : ''}`,
                    { credentials: 'include' }
                );
                const link = await response.text();
                // for testing expired token
                // const link = '/init?token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2NDhkZWExNC05MzQ4LTQzYTctODQ5Zi1hYWYxOGZiYjNlYzgiLCJvdHAiOiIzODI4NTQiLCJjaGFubmVsSWQiOjgwLCJpc3MiOiJnb2JvYXJkaW5nLWdhdGV3YXkiLCJpYXQiOjE3MzQwNTIyNjIsImV4cCI6MTczNDA1Mjg2Mn0.inWRLioBsv80ei1-3JubqT90xhsNHKrbmQJnn2Us36o';
                // request to verify otp and get the index page
                const initRespose = await fetch(
                    `${process.env.REACT_APP_SMARTFACE_BASE_SERVER}${link}`,
                    { credentials: 'include' }
                );
                const initResponseText = await initRespose.text();
                if (initResponseText.startsWith('{')) {
                    try {
                        const initResponseJson = JSON.parse(initResponseText);
                        if (initResponseJson.error) {
                            window.SORTVARS = { error: initResponseJson.error };
                        } 
                    } catch (error) {
                        linkToIndex = ``;
                    }
                } else {
                    linkToIndex = `${initResponseText}`;
                }

                // Perform actions for logged-in users
            }

            if (!window.SORTVARS?.error) {
                // request to get the index page variables
                const indexResponse = await fetch(
                    `${process.env.REACT_APP_SMARTFACE_BASE_SERVER}${linkToIndex}`,
                    { credentials: 'include' }
                );
                if (!indexResponse.ok) {
                    // response.ok is true if status is in the range 200-299
                    const errorJson = await indexResponse.json(); // You can customize this to include more info\
                    window.SORTVARS = { error: errorJson };
                    localStorage.setItem('isLoggedIn', 'false');
                } else {
                    const data = await indexResponse.json();
                    window.SORTVARS = deepCloneWithoutPrototype(data); // Creates an object with no prototype
                    localStorage.setItem('isLoggedIn', 'true');
                }
            }
            if (window.SORTVARS.error) {
                localStorage.setItem('isLoggedIn', 'false');
                // window.document.location.reload();
            }
            if (window.SORTVARS?.geoLocation?.message) {
                window.SORTVARS.geoLocation = {
                    ip: '152.156.219.184',
                    continent_code: 'SA',
                    continent_name: 'South America',
                    country_code2: 'UY',
                    country_code3: 'URY',
                    country_name: 'Uruguay',
                    country_name_official: 'Oriental Republic of Uruguay',
                    country_capital: 'Montevideo',
                    state_prov: 'Montevideo',
                    state_code: 'UY-MO',
                    district: '',
                    city: 'Montevideo',
                    zipcode: '11200',
                    latitude: '-34.90550',
                    longitude: '-56.18512',
                    is_eu: false,
                    calling_code: '+598',
                    country_tld: '.uy',
                    languages: 'es-UY',
                    country_flag:
                        'https://ipgeolocation.io/static/flags/uy_64.png',
                    geoname_id: '6930768',
                    isp: 'Telefonica Moviles del Uruguay SA',
                    connection_type: '',
                    organization: 'Telefonica Moviles del Uruguay SA',
                    country_emoji: '🇺🇾',
                    asn: 'AS19422',
                    currency: {
                        code: 'UYU',
                        name: 'Peso Uruguayo',
                        symbol: '$U',
                    },
                    time_zone: {
                        name: 'America/Montevideo',
                        offset: -3,
                        offset_with_dst: -3,
                        current_time: '2024-09-01 17:58:11.291-0300',
                        current_time_unix: 1725224291.291,
                        is_dst: false,
                        dst_savings: 0,
                        dst_exists: false,
                        dst_start: '',
                        dst_end: '',
                    },
                    country_flag_emoji: '🇺🇾 ',
                };
            }

            ReactDOM.render(
                <React.StrictMode>
                    <DebugProvider
                        initialDebug={debug}
                        initialEnableError={enableError}
                    >
                        <App clientParams={getClientParams(window.SORTVARS)} />
                    </DebugProvider>
                </React.StrictMode>,
                document.getElementById('root')
            );
        } catch (error) {
            console.log(error);
        }
    })();
} else {
    ReactDOM.render(
        <React.StrictMode>
            <DebugProvider
                initialDebug={debug}
                initialEnableError={enableError}
            >
                <App clientParams={getClientParams(window.SORTVARS)} />
            </DebugProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// applyPolyfills().then(() => {
//     defineCustomElements(window);
// });
